import { Callback } from '@ngtools/webpack/src/webpack';

export function mapToObject(rows : Iterable<any>, callback : (any) => any) : any {
    let data = {};

    for(let column of rows) {
        let value = callback(column);
        data = { ... value, ... data };
    }

    return data;
}